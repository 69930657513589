import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SliderWidgetContainer as SourceSliderWidgetContainer,
} from 'SourceComponent/SliderWidget/SliderWidget.container';
import {Slider} from 'SourceQuery/Slider.type';
import {NotificationType} from 'Store/Notification/Notification.type';

import SliderQuery from '../../query/Slider.query';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Steinkrueger/Component/SliderWidget/Container */
export class SliderWidgetContainer extends SourceSliderWidgetContainer {
    requestSlider(): void {
        const { sliderId, showNotification, isOffline } = this.props;
        const {
            actionName: {
                slider: {
                    slider_id: preloadedSliderId,
                } = {},
                slider: preloadedSlider = {},
            },
        } = window;

        if (sliderId === Number(preloadedSliderId)) {
            const slider = preloadedSlider;
            this.setState({ slider });

            return;
        }

        this.fetchData<{ slider: Slider }>(
            [SliderQuery.getQuery({ sliderId: String(sliderId) })],
            ({ slider }) => this.setState({ slider }),
            (e) => showNotification(NotificationType.ERROR, __('Error fetching Slider!'), e),
            isOffline,
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderWidgetContainer);
