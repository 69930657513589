import { Field, Query } from '@tilework/opus';

import { Slide, Slider } from 'SourceQuery/Slider.type';

/**
 * Slider Query
 * @class Slider
 * @namespace Steinkrueger/Query/Slider/Query */
export class SliderQuery {
    getQuery(options: { sliderId: string }): Query<'slider', Slider> {
        const { sliderId } = options;

        return new Query<'scandiwebSlider', Slider>('scandiwebSlider')
            .addArgument('id', 'ID!', sliderId)
            .addFieldList(this._getSliderFields())
            .setAlias('slider');
    }

    _getSliderFields(): Array<
    Field<'slides', Slide, true>
    | Field<'slide_speed', number>
    | Field<'slides_to_display', number>
    | Field<'slides_to_display_mobile', number>
    | Field<'slides_to_display_tablet', number>
    | Field<'slides_to_scroll', number>
    | Field<'slides_to_scroll_mobile', number>
    | Field<'slides_to_scroll_tablet', number>
    | Field<'slider_id', number>
    | Field<'title', string>
    > {
        return [
            this._getSlidesField(),
            this._getSlideSpeedField(),
            this._getSlideToDisplayField(),
            this._getSlideToDisplayMobileField(),
            this._getSlideToDisplayTabletField(),
            this._getSlidesToScrollField(),
            this._getSlidesToScrollMobileField(),
            this._getSlidesToScrollTabletField(),
            new Field<'slider_id', number>('slider_id'),
            new Field<'title', string>('title'),
        ];
    }

    _getSlideFields(): Array<
    Field<'slide_text', string>
    | Field<'slide_id', number>
    | Field<'mobile_image', string>
    | Field<'desktop_image', string>
    | Field<'title', string>
    | Field<'is_active', boolean>
    > {
        return [
            new Field<'slide_text', string>('slide_text'),
            new Field<'slide_id', number>('slide_id'),
            new Field<'mobile_image', string>('mobile_image'),
            new Field<'desktop_image', string>('desktop_image'),
            new Field<'title', string>('title'),
            new Field<'is_active', boolean>('is_active'),
        ];
    }

    _getSlidesField(): Field<'slides', Slide, true> {
        return new Field<'slides', Slide, true>('slides', true)
            .addFieldList(this._getSlideFields());
    }

    _getSlideSpeedField(): Field<'slide_speed', number> {
        return new Field<'slide_speed', number>('slide_speed');
    }

    _getSlideToDisplayField(): Field<'slides_to_display', number> {
        return new Field<'slides_to_display', number>('slides_to_display');
    }

    _getSlideToDisplayMobileField(): Field<'slides_to_display_mobile', number> {
        return new Field<'slides_to_display_mobile', number>('slides_to_display_mobile');
    }

    _getSlideToDisplayTabletField(): Field<'slides_to_display_tablet', number> {
        return new Field<'slides_to_display_tablet', number>('slides_to_display_tablet');
    }

    _getSlidesToScrollField(): Field<'slides_to_scroll', number> {
        return new Field<'slides_to_scroll', number>('slides_to_scroll');
    }

    _getSlidesToScrollMobileField(): Field<'slides_to_scroll_mobile', number> {
        return new Field<'slides_to_scroll_mobile', number>('slides_to_scroll_mobile');
    }

    _getSlidesToScrollTabletField(): Field<'slides_to_scroll_tablet', number> {
        return new Field<'slides_to_scroll_tablet', number>('slides_to_scroll_tablet');
    }
}

export default new SliderQuery();
