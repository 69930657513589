/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import SlickSlider from 'Component/SlickSlider/SlickSlider.container';
import Slider from 'Component/Slider';
import {SliderWidgetComponentProps, SlideWithPlaceholder} from 'Component/SliderWidget/SliderWidget.type';
import {
    SliderWidgetComponent as SourceSliderWidgetComponent,
} from 'SourceComponent/SliderWidget/SliderWidget.component';
import {ReactElement} from 'Type/Common.type';
import {debounce} from 'Util/Request/Debounce';
import {AfterPriority} from 'Util/Request/LowPriorityRender';

import './SliderWidget.override.style';

/** @namespace Steinkrueger/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidgetComponent {
    componentDidUpdate(
        prevProps: SliderWidgetComponentProps,
    ): void {
        const { slider: { slide_speed: slideSpeed, slides } } = this.props;
        const { slider: { slide_speed: prevSlideSpeed } } = prevProps;

        if (!slideSpeed) {
            return;
        }

        if (slideSpeed !== prevSlideSpeed && slides?.length !== 1) {
            this.changeSlideDebounced = debounce(this.changeSlide.bind(this), slideSpeed);
        }
    }

    renderSlide(slide: SlideWithPlaceholder, i: number): ReactElement {
        const { activeImage } = this.state;
        const { isPriorityLoaded } = window;

        if (activeImage !== i && !isPriorityLoaded) {
            return (
                <AfterPriority fallback={ <div /> } key={ i }>
                    { this.renderSlideImage(slide, i) }
                </AfterPriority>
            );
        }

        return this.renderSlideImage(slide, i);
    }

    render(): ReactElement {
        const { activeImage } = this.state;
        const { slider: { slides, title: block } } = this.props;
        const { slider } = this.props;

        if (!slides) {
            return null;
        }

        if (this.props.slider.slides_to_display && this.props.slider.slides_to_display > 1) {
            return (
                <SlickSlider { ...slider }>
                    { slides.map(this.renderSlide.bind(this)) }
                </SlickSlider>
            );
        }

        if (slider.title?.includes('Home page slider')) {
            const homeSliderProps = {
                ...slider,
                isHomeSlider: true,
            };

            return (
                <SlickSlider { ...homeSliderProps }>
                    { slides.map(this.renderSlide.bind(this)) }
                </SlickSlider>
            );
        }

        return (
            <Slider
              mix={ { block: 'SliderWidget', mix: { block } } }
              showArrows
              showCounter
              activeImage={ activeImage }
              onActiveImageChange={ this.onActiveImageChange }
            >
                { slides.map(this.renderSlide.bind(this)) }
            </Slider>
        );
    }
}

export default SliderWidgetComponent;
